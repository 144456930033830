import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

axios.defaults.headers.common.Authorization =
  'Bearer ' + localStorage.getItem('accessToken')

Vue.prototype.$http = axiosIns

export default axiosIns
