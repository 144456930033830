<template>
  <b-col sm="12" md="12" xl="12" class="p-0">
    <b-card-text>
      <b-form-checkbox v-model="kecelakaanActive" v-if="!disabled">
        <h5>Kecelakaan {{ kecelakaanActive ? ':' : '' }}</h5>
      </b-form-checkbox>
      <h5 v-else>Kecelakaan :</h5>
      <Transition name="fold" mode="out-in">
        <b-row v-if="kecelakaanActive">
          <b-col lg="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Jenis Kecelakaan"
              >
                <b-form-select v-model="kecelakaan" :disabled="disabled" >
                  <template #first>
                    <b-form-select-option :value="null" disabled>Jenis Kecelakaan</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="item in jenisKecelakaan" :key="item.value" :value="item.value">{{ item.title }}</b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col lg="8">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="No. Laporan Polisi"
            >
              <b-form-group>
                <b-form-input v-model="noLaporanPolisi" placeholder="No. Laporan Polisi" :disabled="disabled" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col lg="2">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required|date_format:yyyy/MM/dd"
                name="Tgl. Kejadian"
              >
                <b-form-datepicker v-model="tanggal" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :disabled="disabled" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group>
              <b-form-input v-model="keterangan" :disabled="disabled" placeholder="Keterangan" />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group>
              <b-form-select v-model="penjamin" :disabled="disabled" placeholder="Penjamin" >
                <template #first>
                  <b-form-select-option :value="null" disabled>Penjamin</b-form-select-option>
                </template>
                <b-form-select-option v-for="item in asuransiKecelakaan" :key="item.value" :value="item.value">{{ item.title }}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="d-flex align-items-center">
            <b-form-group>
              <b-form-checkbox v-model="suplesi" :disabled="disabled" >
                Suplesi ?
              </b-form-checkbox>
            </b-form-group>
            <b-form-group style="flex: 1;" class="ml-1">
              <validation-provider
                #default="{ errors }"
                :rules="`${suplesi ? 'required' : ''}`"
                name="No. SEP Suplesi"
              >
                <b-form-input v-model="suplesiSep" placeholder="No. SEP Suplesi" :disabled="!suplesi || disabled" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Provinsi"
              >
                <b-form-select v-model="provinsi" :disabled="disabled" >
                  <template #first>
                    <b-form-select-option :value="null" disabled>Provinsi</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="item in provinces" :key="item.value" :value="item.value">{{ item.title }}</b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Kab/Kota"
              >
                <b-form-select v-model="kabKota" :disabled="disabled" >
                  <template #first>
                    <b-form-select-option :value="null" disabled>Kab/Kota</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="item in cities" :key="item.value" :value="item.value">{{ item.title }}</b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Kecamatan"
              >
                <b-form-select v-model="kecamatan" :disabled="disabled" >
                  <template #first>
                    <b-form-select-option :value="null" disabled>kecamatan</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="item in districts" :key="item.value" :value="item.value">{{ item.title }}</b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </Transition>
    </b-card-text>
  </b-col>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PanelKecelakaan',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      kecelakaanActive: this.value.status || false,
      kecelakaan: this.value.jenis_kecelakaan || null,
      noLaporanPolisi: this.value.no_laporan_polisi || null,
      keterangan: this.value.keterangan || null,
      tanggal: this.value.tanggal_kejadian || null,
      penjamin: this.value.penjamin || null,
      suplesi: this.value.suplesi || null,
      suplesiSep: this.value.no_sep_suplesi || null,
      provinsi: this.value.kode_provinsi || null,
      kabKota: this.value.kode_kabupaten || null,
      kecamatan: this.value.kode_kecamatan || null,
      asuransiKecelakaan: [],
      jenisKecelakaan: [],
      cities: [],
      districts: [],
    }
  },
  created() {
    this.setData();
  },
  computed: {
    provinces() {
      return this.$store.getters['masterdata/provinsi'];
    },
    allData() {
      return {
        jenis_kecelakaan: this.kecelakaan,
        no_laporan_polisi: this.noLaporanPolisi,
        keterangan: this.keterangan,
        penjamin: this.penjamin,
        tanggal_kejadian: this.tanggal,
        suplesi: this.suplesi,
        no_sep_suplesi: this.suplesiSep,
        kode_provinsi: this.provinsi,
        kode_kabupaten: this.kabKota,
        kode_kecamatan: this.kecamatan,
        status: this.kecelakaanActive,
      };
    }
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.setData();
      },
    },
    provinsi() {
      this.getCities();
      this.getDistricts();
    },
    kabKota() {
      this.getDistricts();
    },
    allData: {
      handler() {
        this.$emit('input', this.allData); // Manually emit the input event
      },
      deep: true
    }
  },
  methods: {
    setData() {
      this.kecelakaanActive = this.value.status || false,
      this.kecelakaan = this.value.jenis_kecelakaan || null,
      this.noLaporanPolisi = this.value.no_laporan_polisi || null,
      this.keterangan = this.value.keterangan || null,
      this.tanggal = this.value.tanggal_kejadian || null,
      this.penjamin = this.value.penjamin || null,
      this.suplesi = this.value.suplesi || null,
      this.suplesiSep = this.value.no_sep_suplesi || null,
      this.provinsi = this.value.kode_provinsi || null,
      this.kabKota = this.value.kode_kabupaten || null,
      this.kecamatan = this.value.kode_kecamatan || null,
      this.getAsuransiKecelakaan()
      this.getJenisKecelakaan()
      this.getCities()
      this.getDistricts()
    },
    unsetData() {
      this.kecelakaanActive = false
      this.kecelakaan = null
      this.noLaporanPolisi = null
      this.keterangan = null
      this.tanggal = null
      this.penjamin = null
      this.suplesi = null
      this.suplesiSep = null
      this.provinsi = null
      this.kabKota = null
      this.kecamatan = null
    },
    getAsuransiKecelakaan() {
      axios.get('/referensi/184?active=true').then(res => {
        this.asuransiKecelakaan = res.data?.data || [];
      });
    },
    getJenisKecelakaan() {
      axios.get('/referensi/183?active=true').then(res => {
        this.jenisKecelakaan = res.data?.data || [];
      });
    },
    getCities() {
      if (!this.provinsi) {
        if (!this.disabled) this.kabKota = null;
        if (!this.disabled) this.kecamatan = null;
        this.cities = [];
        this.districts = [];
        return;
      }

      axios.get(`/wilayah/kota/${this.provinsi}?jenis=2`).then(res => {
        this.cities = res.data?.data || [];
      });
    },
    getDistricts() {
      if (!this.kabKota) {
        if (!this.disabled) this.kecamatan = null;
        this.districts = [];
        return;
      }

      axios.get(`/wilayah/kecamatan/${this.kabKota}?jenis=3`).then(res => {
        this.districts = res.data?.data || [];
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.fold-enter-active,
.fold-leave-active {
  transition: all 0.25s;
}
.fold-enter,
.fold-leave-to {
  transform: scaleY(0) translateY(-100%);
  opacity: 0;
}
</style>