import { Ability } from '@casl/ability'
/* import { getCookie } from '../cookie' */
import sLocalStorage from '@core/utils/secure-storage'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(sLocalStorage.getItem('userData'))
// const userData = getCookie('userData')

const existingAbility = userData ? userData.ability : null

export default new Ability(existingAbility || initialAbility)
