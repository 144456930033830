import useJwt from '@/auth/jwt/useJwt'
import sLocalStorage from '@core/utils/secure-storage'
/* import { getCookie } from '@/libs/cookie' */

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    sLocalStorage.getItem('userData') &&
    localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  )
  //return getCookie('userData') && getCookie(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => {
  const userData = JSON.parse(sLocalStorage.getItem('userData'))

  return {
    ...userData,
    entity: !userData.entity
      ? {
          name: 'RSU DADI KELUARGA CIAMIS',
          address: 'Jl. R. E. Martadinata No.333-335, Petirhilir, Baregbeg',
          code: '-',
          phone: '-',
          logo: require('@/assets/gambar/logo.png')
        }
      : userData.entity
  }
}
//export const getUserData = () => JSON.parse(getCookie('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'client') return { name: 'access-control' }
  else return '/'

  // return { name: 'auth-login' }
}
